// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";


// Theme colors
$primary:#B16A24;
$secondary: #6C7732;
$dark-green:#2C3E26;

$green: #80875A;
$ether: #9FB5B7;

$caramel: #D5893D;
$guava: #FF952A;
$brown: #B16A24;

$gray: #707070;
$white: #ffffff;
$black: #000000;

$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "dark-green": $dark-green,
  "ether":      $ether,
  "green":      $green,
  "caramel":    $caramel,
  "guava":      $guava,
  "light":      $white,
  "brown":      $brown,
  "gray":       $gray,
  "white":      $white,
  "black":      $black,
);
$enable-negative-margins: true;
// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Body
// $body-font-weight: 700;
$font-weight-base: 400;
$headings-font-weight: 800;
// Fonts
$primary-text-emphasis: #fff;
$body-emphasis-color: #fff;
$body-color: #000000;
$font-family-base: 'Montserrat', Arial, Helvetica, sans-serif;
$font-size-base: 1rem;

// Links
$link-color: $white;
$link-decoration: none;

// Navbar
$navbar-light-icon-color: #fff;

// Font
$font-size-root: 16px;
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

$h1-font-size: 4.188rem;    //67px
$h2-font-size: 3.125rem;    //50px
$h3-font-size: 2.438rem;    //39px
$h4-font-size: 1.875rem;    //30px
$h5-font-size: 1.5rem;      //24px
$h6-font-size: 1.25rem;     //20px

$lead-font-size: 1.25rem;     //20px
$lead-font-weight: 500;     //medium

// Navbar
$navbar-padding-y:0;
$nav-link-padding-y:1.5rem;
$nav-link-hover-color:#FFFFFF;
$nav-link-font-size:16px;
$navbar-light-color:#FFFFFF;
$navbar-nav-link-padding-x:1rem;
$navbar-light-hover-color: #FFFFFF;
// Dropdown
$dropdown-color: #fff;
$dropdown-bg: $primary;
$dropdown-border-radius: 0;
$dropdown-spacer: -1px;
$dropdown-border-width:0;
$dropdown-link-active-bg: $primary;
$dropdown-min-width: 100%;

// Button
$btn-border-radius: 0px;
$btn-padding-x:1.25rem;
$btn-font-weight: 700;
$btn-color: #fff;

// Form
$focus-ring-width: 1px;
$input-border-radius:  0px;
$input-bg: $white;
$input-focus-border-color: $brown;
$input-focus-color:  $primary;
$input-btn-padding-y: .5rem;

// Accordion
$accordion-button-active-bg: #fff;
$accordion-button-color: $primary;

$accordion-button-active-bg: #fff;          
$accordion-button-active-color: $primary;

$pagination-active-color: #fff;
$pagination-active-bg: $primary;  
$pagination-active-border-color: $primary;

$navbar-toggler-border-radius:5px;
