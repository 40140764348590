@import "variables";

header{
    .header-contact{
        display: flex;
        a{
            transition: color .3s ease-in-out;
            &:hover{
                color: $guava;
                svg{
                    fill: $guava;
                }
            }
        }
    }
       
    
    .navbar-brand{
        position: relative;
        min-width: clamp(200px, 20vw, 240px);
        height: 60px;
        padding: 0;
        padding-right: 3rem;
        padding-left: 1rem;
        align-self: center;
        text-align: right;
        z-index:1001;
        &:hover{
            img{
                filter: opacity(.5) drop-shadow(0 0 0 #D5893D) saturate(1000%);
            }
            
        }
        img{
            
            transition: filter .3s ease-in-out;
            width: 100%;
            height: 100%!important;
            object-fit: contain;
        }
    }
    .nav-link{
        height: 100%;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        
    }
 
    .nav-item.dropdown{
        height: 100%;
        display: flex;
        align-items: center;
        &:hover{
            background: $primary;
        }
    }
    .dropdown-menu .dropdown-menu {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
    }

    .depth-0 > .nav-item > .btn-group > .dropdown-toggle-split{
        padding-left: .25rem;
        padding-right: .25rem;
    }
    .depth-1{
        .nav-item:hover{
            background-color: $guava;
            
        }
        .dropdown-toggle::after{
            margin-left: 1rem;
        }
    } 
    .dropdown-menu> li:hover > .btn-group > .dropdown-menu {
        display: block;
        
    }
    .dropdown-menu{

        padding-top: 0;
        padding-bottom:0;
        & > .nav-item:first-child{
            margin-top: 1px;
            border-top: 1px solid white;

        }
        .nav-link{
            padding: .5rem 0;
            margin: 0;
 
        }
    }

    .dropdown-toggle.show{
        background-color: $orange;
    }
    .dropdown-toggle-split, .dropdown-toggle{
        display: flex;
        align-items: center;
        &:hover{
            background: $primary;
            color: $white;
        }
    }
    .btn-group{
        height: 100%;
        & > .nav-link, & > .dropdown-toggle{
            transition: background-color .15s ease-in-out;
        }
        &:hover{
            & > .nav-link, & > .dropdown-toggle{
                background: $primary;
            }
        }
        .dropdown-toggle-split:after{
            display: none;
        }
    }
    @include media-breakpoint-down(xl) {
        .navbar-brand img{
            top:0;
        }
    }
    ul.depth-0 > .nav-item {
        & > .nav-link, & > .btn-group > .nav-link{
            font-size: 16px;
            font-weight: 400;
        }
        &:hover{
            & > .nav-link, & > .btn-group > .nav-link{
                font-weight: 700;
                color: #fff !important;;
            } 
            & > .nav-link{
                background: $primary;
            }
        }
    }
}
.offcanvas-header{
    background-color: $primary;
    color: var(--bs-white);
}
.offcanvas-body{
    .btn{
        color:$black;
    }
}
.collapse-menu{
    .has-collapse-button{
        align-items: center;
    }
}

.site-links{
    .icon svg{
        width: 25px;
        min-width: 25px;
        height: 25px;
        object-fit: contain;
        transition: fill .3s ease-in-out; 
    }
}