@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,700;0,800;1,800&display=swap');

@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/glightbox/dist/css/glightbox.css";

// Our own scss below

@import "header";
@import "splash";
@import "footer";
@import "blocks";
@import "page";
@import "forms";

.short-hr{
    display: none;
    max-width: 112px;
    width: 100%;
    height: 1px;
    background-color: #707070;
    margin-bottom: 2rem;
    @include media-breakpoint-down(md) {
        max-width: 100%;
        margin-top: 2rem;
    }
}
.cke_editable{
    background-color: #dfdfdf;
}

// Apply to picture or image wrapper
.img-tint{
    background: #515151;
    img{
        mix-blend-mode: multiply;
    }
}
.img-tint-hover{
    background-color: rgba(81, 81, 81, .38);
    transition: background-color .3s ease-in-out;
    img{
        mix-blend-mode: multiply;
    }
    &:hover{
       background-color: rgb(255, 255, 255); 
    }
    
}
.fullwidth{
    height: auto!important;
    width: 100%;
}
@include media-breakpoint-down(sm) {
    h1, .h1 {
    font-size: clamp(1.5rem,10vw,2.75rem);
    }
}


.row-list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    padding:0;
    margin:0;
    width: 100%;
    list-style:none;

    gap:1.5rem;
    & > *{
        display: flex;
        flex-direction: column;
     
    }
    @include media-breakpoint-down(md) {
        // flex-wrap: wrap;
        justify-content: center;
    }
}
.block-cta-block{
    background-color: #707070;

    .cta-card{
        display: block;
        aspect-ratio: 3/4;
        &:hover{
            picture{
                background-color: transparent;
            }
        }
        
        .cta-title, h2{
            font-size: clamp(24px, 3vw, 50px);
        }
        h3{
            font-size: clamp(20px, 2.75vw, 39px);
        }
        picture{
            content:"";
            position: absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            display: block;
            z-index: -2;
            background-color: #A8A8A8;
            transition: background-color .3s ease-in-out;
        
            img{
                mix-blend-mode: multiply;

            }
          
        }
     
    }
}
.multiply{
    mix-blend-mode: multiply;
}

.row-list-centered{
    @extend .row-list;
    justify-content: center;
}
.two-column-list{
    padding:0;
    margin:0;
    width: 100%;
    list-style:none;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap:1rem;
    @include media-breakpoint-down(md) {
        grid-template-columns: 1fr;
    }
    li{
        display: flex;
        flex-direction: column;
        img{
            width: 100%!important;
            height: auto!important;
            margin:auto
        }
    }
}
.three-column-list{
    @extend .two-column-list;
    grid-template-columns: repeat(3, 1fr);
    grid-gap:3rem;
    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
    }
    li{
        position: relative;
    }
    li::after{
        right:-1.5rem;
        top:50%;
        height: 55px;
        background-color: #EBE9E9;
        width: 2px;
        transform: translateY(-50%);
        content:"";
        position: absolute;

    }
    li:last-child::after{
        display: none;
    }
}
.accordion-button{
    font-size: 2.706rem;
}
.btn{
    white-space: nowrap;
    // font-size:16px;
    font-weight: 500;
    display: inline-flex;
    position: relative;
    border: 0;
    color:$white;
    transition: none;
    margin-right: 35px;
    &::after{
        content:url("../assets/triangle.svg");
        aspect-ratio: 1 / 1;
        height: 100%;
        width: auto;
        position: absolute;
        left:100%;
        top:0;
        bottom: 0;
       

    }
    &:hover{
        color: white;
    }
   
}
.btn-primary{
    &::after{
        filter: invert(41%) sepia(99%) saturate(399%) hue-rotate(349deg) brightness(90%) contrast(87%);
    }
    &:hover{
        &::after{
            filter: invert(72%) sepia(13%) saturate(5130%) hue-rotate(336deg) brightness(86%) contrast(67%);
        }
    }
}
.btn-green{
    &::after{
        filter: invert(55%) sepia(20%) saturate(600%) hue-rotate(29deg) brightness(90%) contrast(88%);
    }
    &:hover{
        &::after{
            filter: invert(70%) sepia(13%) saturate(654%) hue-rotate(29deg) brightness(83%) contrast(87%);
        }
    }
}
.btn-dark-green{
    &::after{
        filter: invert(19%) sepia(14%) saturate(1179%) hue-rotate(60deg) brightness(96%) contrast(90%);
    }
    &:hover{
        &::after{
            filter: invert(16%) sepia(11%) saturate(1666%) hue-rotate(61deg) brightness(94%) contrast(90%);
        }
    }
}
.btn-caramel{
    &::after{
        filter: invert(62%) sepia(46%) saturate(659%) hue-rotate(347deg) brightness(88%) contrast(88%);
    }
    &:hover{
        &::after{
            filter: invert(67%) sepia(50%) saturate(471%) hue-rotate(347deg) brightness(91%) contrast(87%);
        }
    }
}
.btn-black{
    &::after{
        filter: brightness(0);
    }
    &:hover{
        &::after{
            filter: brightness(0);
        }
    }
}


blockquote {
    font-weight: 900;
    font-size: 30px;
    font-style: italic;
}
.collapse-menu .btn::after{
    display: none;
}

.text-green{
    color:var(--bs-green);
}

.opening-hours{
    display: flex;
    flex-direction: column;
}
table td{
    padding-bottom: .5rem;
    padding-top: .5rem;
}
label.float {
    font-weight: 100;
}


.call-cta{
    & > div{
        width: clamp(150px, 20vw, 260px);
        text-align: center;
        aspect-ratio: 1/1;
        a{
            font-size: clamp(24px, 4vw, 60px);
        }
    }

    @include media-breakpoint-down(md) {
        left: 0;
        display: flex;
        justify-content: center;
    }
}
.bg-dirty-white{
    background-color: #F8F8F8;
}