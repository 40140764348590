
.block-image-content, .block-content-image{
    .small-icon{
        width: clamp(50px, 20vw, 226px);
        display: block;
        margin-top: -2rem;
    }
}
.block-content-bg-image{
    & > div{
        min-height: 375px;
    }
}

.block-contact-form{
    // & > div > picture > img{
    //     opacity: .88;
    // }
    background-color: rgba(177, 106, 36 , .88);
    .site-links{
        a:hover{
            svg{
                fill:#fff;
            }
        }
    }
    .contact-details{
        a{
            transition: all .3s ease-in-out;
            &:hover{
                color:#000;
            }
        }
    }
}