@import "variables";

.t-home{
    #splash-wrap{
        background-color: rgba(40, 75, 107, .2);
     }

}

.t-contact{
    .contact-map{
        --bs-aspect-ratio:clamp(300px, 35%, 700px);
    }
}
.site-links{
    svg{
        height: 36px;
        width: 36px;
    }
}
.contact-details{
    svg{
        width: 30px;
        height: 30px;
        object-fit: contain;
   
    }
}