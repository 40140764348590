@import "variables";

label.float{
    min-height: 57px;
    line-height: 57px;
    text-transform:none;
}

input{
    min-height: 57px;
}
.recaptcha-notice{
    color: #fff;
    margin-top: 2.2rem;
}