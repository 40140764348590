
.carousel-indicators {
    top: 0;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 20px;
    padding-top: calc(25vh + 6vw + 20rem);
    pointer-events: none;
    
    gap:1rem;
    [data-bs-target] {
        width: 50px;
        pointer-events:all;
    }
}
.carousel-caption{
    padding-top: calc(25vh + 100px + 5vw);
    position: absolute;
    z-index: 10;
}
#splash-wrap{
    background-color:#9F9F9F;
    min-height:100vh; 
    height: 100vh;
    img{
        mix-blend-mode: multiply;
        pointer-events: none;
    }
    .splash-content{
        picture{
            display: block;
            width: 100%;
            height: 100%;
            &::before,&::after{
                content:"";
                position: absolute;
                top:0;
                right:0;
                left:0;
                bottom:0; 
            }
            &::before{
                background-image: url("../assets/splash_logo.svg");
                background-repeat: no-repeat;
                background-position:top right ;
                background-size: contain;
                mix-blend-mode: soft-light;
                z-index: 7;
                pointer-events: none;
                @media screen and (max-width:768px){
                    background-size: cover;
                }
            }
        
            &::after{
                background-color: #9F9F9F;
                mix-blend-mode: multiply;
                z-index: 6;
            }
            img{
                z-index: 5;
            }
        }
    }
}

