@import "variables";

footer{

    background-color: #000000;
 
    .logo{
        width: 100%; 
        max-width: 400px;
        height: auto;
        // transform: scale(1.2);
        img{
            transition: filter .3s ease-in-out;
        }
        &:hover{
            img{
                filter: opacity(.5) drop-shadow(0 0 0 #D5893D) saturate(1000%);
            }
            
        }
    }
    .site-map{
        .depth-0{
            margin-bottom: 2rem;
            & > .nav-link{
                font-weight: 700;
                padding-bottom: 2rem;
                color:#fff;
                &> .nav-item:first-child{
                    // position: relative;
                    &::after{
                        content:'';
                        display: block;
                        width: 100vw;
                        height: 1px;
                        background-color: #fff;
                        position: absolute;
                        left:0;
                        margin-top: 1rem;
                    }
                }
            } 
            &.has-sub{
                ul{
                    display: flex;
                    flex-direction: column;
                }
            }
        }
        .nav-link{
            padding:10px 10px;
            max-width: 185px;
            font-size: 16px;
            &:hover{
                color: var(--bs-brown)!important;
            }
        }
        .depth-1{
            .nav-link{
                color: #979797;
                padding:5px 10px;
            }
        
        }
    }

    .bykeo a{
        color: #fff;
        font-size: 18px;
        
    } 
    .footer-contact, .footer-contact a{
        color: #fff;
        
    }
    .footer-contact {
        svg{
            transition: fill .3s ease-in-out; 
            width: 30px;
            height: 30px;
            object-fit: contain;
        }
        a{
            transition: color .3s ease-in-out;
            &:hover{
                color: $brown;
                svg{
                    fill: $brown;
                }
            }
        }
    }
}